import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../utils/BoldContentful";

const Container = styled.div`
  width: 100%;
  display: flex;
  //height:650px;
  justify-content: center;
  @media (max-width: 900px) {
    height: auto;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    width: calc(100% - 60px);
    flex-direction: column-reverse;
  }
`;
const Leftcontainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px 5%;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 0%;
  }
`;

const Rightcontainer = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Titlecontainer = styled.div`
  color: var(--gris);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;

  p:first-child {
    font-weight: 700;
    font-size: 38px;
    color: ${(p) => p.co};
    margin-bottom: 12px;
  }
`;

const Historianorich = ({ texto, children, fontc, fontcb }) => {
  
  return (
    <Container>
      <Subcontainer>
        <Leftcontainer>
          <Titlecontainer co={fontc}>
            {BoldContentful(texto, "fontcb")}
          </Titlecontainer>
        </Leftcontainer>
        <Rightcontainer>{children}</Rightcontainer>
      </Subcontainer>
    </Container>
  );
};

export default Historianorich;
